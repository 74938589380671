<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" item-key="_id"
			:options.sync="options" :server-items-length="totalItems"
			:loading="loading" loading-text="加载中...">
            <template v-slot:top>
				<div class="d-flex flex-wrap">
                <v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
                    <v-btn>全部待交付</v-btn>
                    <v-btn>评估后待交付</v-btn>
                    <v-btn>已交付</v-btn>
                </v-btn-toggle>
				<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto" class="ml-4"
					append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
				</div>
                <v-divider/>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            </template>
			
			<template v-slot:item.evalConclusion="{ item }">
				{{item.evalConclusion ? (item.evalConclusion.gastroPrompt3 ? '4袋' : '常规') : ''}}
			</template>
            <template v-slot:item.progress.evalConclusion="{ item }">
                {{formatTime(item.progress.evalConclusion)}}
            </template>
            <template v-slot:item.progress.requestSupplies="{ item }">
                {{formatTime(item.progress.requestSupplies)}}
            </template>
            <template v-slot:item.progress.deliverSupplies="{ item }">
                {{formatTime(item.progress.deliverSupplies)}}
            </template>
            <template v-slot:item.idcard="{ item }">
                {{getMaskedIdcard(item)}}
            </template>
            <template v-slot:item.opts="{ item }">
                {{getOptsStr(item)}}
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent max-width="540">
				<v-card>
					<v-card-title>交付清肠药</v-card-title>
					<v-card-text>
						<v-form ref="dsform" :readonly="locked">
							<v-container>
								<v-row>
									<v-col cols="12" sm="4"><v-text-field label="姓名" v-model="currentItem.pii.username" readonly dense/></v-col>
									<v-col cols="12" sm="4">
										<v-select label="交付方式" v-model="deliverSuppliesWay" placeholder="请选择交付方式"
											:items="['邮寄','面交']" dense :rules="wayRules" required :readonly="phase2"/>
									</v-col>
									<v-col cols="12" sm="4">
										<v-select label="申请数量" v-model="deliverSuppliesQuantity" placeholder="请选择申请数量"
											:items="['3','4']" dense :rules="wayRules" required :readonly="phase2"/>
									</v-col>
								</v-row>
								<v-text-field label="邮寄地址" v-model="deliverSuppliesAddress" dense
									:disabled="deliverSuppliesWay!=='邮寄'" :rules="addressRules" :readonly="phase2"/>
								<v-text-field label="快递单号" v-model="deliverSuppliesTracking" dense
									:disabled="deliverSuppliesWay!=='邮寄'" :readonly="!phase2"/>
							</v-container>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<template v-if="phase2">
							<v-btn color="primary" @click="saveDelivered" :loading="loadingsave" :disabled="loadingsave||locked">确认已交付清肠药</v-btn>
						</template>
						<template v-else>
							<v-btn color="primary" @click="save" :loading="loadingsave" :disabled="loadingsave||locked">保存</v-btn>
						</template>
						<v-btn @click="dialog=false">取消</v-btn>
					</v-card-actions>
				</v-card>
        </v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import {formatTime} from '../utils'
	import store from '../store.js'

    export default {
        name: 'Order',
        data() {
            return {
				authed: false,
				phase2: false,
                headers: [
                    {text:'', value:'actions', width:40, sortable: false},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'诊所', value:'region', width:100},
                    {text:'电话', value:'pii.phone', width:120},
                    {text:'清肠药评估', value:'evalConclusion', width:140},
                    {text:'交付方式', value:'deliverSupplies.Way', width:120},
                    {text:'申请数量', value:'deliverSupplies.Quantity', width:100},
                    {text:'邮寄地址', value:'deliverSupplies.Address', width:300},
                    {text:'快递单号', value:'deliverSupplies.Tracking', width:150},
                    {text:'评估完成时间', value:'progress.evalConclusion', width:180},
                    {text:'信息更新时间', value:'progress.requestSupplies', width:180},
                    {text:'交付完成时间', value:'progress.deliverSupplies', width:180},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'套餐内容', value:'opts', sortable: false, width:240},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'idcard', width:120},
                ],
                items: [],
                loading: false,
                dialog: false,
                loadingsave: false,
				locked: false,
                currentItem: {pii:{}},
                currentState: false,
				deliverSuppliesWay: '',
				deliverSuppliesQuantity: '3',
				deliverSuppliesAddress: '',
				deliverSuppliesTracking: '',
				wayRules: [v => !!v || '此项为必填'],
				addressRules: [v => !!v || (this.deliverSuppliesWay!=='邮寄') || '此项为必填'],
				filter: 0,
				options: {},
				totalItems: 0,
				pname: ''
            }
        },
        mounted() {
			this.phase2 = this.$hasPrivilege('交付清肠药');
			this.authed = this.phase2 || this.$hasPrivilege('健康顾问');
			if (!this.authed) return;
            this.formatTime = formatTime;
			this.$watch(()=>store.currentRegion, this.fetchData);
            this.fetchData();
		},
        methods: {
            async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [{'opts.GIE':_.neq('胃镜')}];
				filter.push(_.and([
					{'endoscopyResult.pathology' : _.neq('无')}, {'progress.pathology' : _.exists(false)}	//未完成的订单
				]))
				if (this.$hasPrivilege('交付清肠药')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				filter.push({category:_.neq('clinic')});
				filter.push({region:store.currentRegion});
				const filters = [
					[{'progress.deliverSupplies':_.exists(false)}],
					[{'progress.deliverSupplies':_.exists(false)}, {'evalConclusion':_.exists(true)}, {'evalConclusion.anesConclusion':_.neq('未通过')}],
					[{'progress.deliverSupplies':_.exists(true)}],
				];
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
            },
			getMaskedIdcard(item) {
				return item.pii.idcard;
//				return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
			},
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
            editItem(item) {
                this.currentItem = item;
				this.deliverSuppliesWay = item.deliverSupplies?.Way || '';
				this.deliverSuppliesQuantity = item.deliverSupplies?.Quantity || '';
				this.deliverSuppliesAddress = item.deliverSupplies?.Address || '';
				this.deliverSuppliesTracking = item.deliverSupplies?.Tracking || '';
				this.locked = !!item.progress.deliverSupplies;
                this.dialog = true;
            },
            async save() {
				this.$refs.dsform.validate();
				if (!this.$refs.dsform.validate()) return;
				this.loadingsave = true;
				try {
					const id = this.currentItem._id;
					if (this.deliverSuppliesWay === '面交') {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'deliverSuppliesByFace',
								data: { id, Quantity:this.deliverSuppliesQuantity }
							}
						});
					} else {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'requestSuppliesByMail',
								data: { id, Quantity:this.deliverSuppliesQuantity, Address:this.deliverSuppliesAddress }
							}
						});
					}
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);
				}
				this.loadingsave = false;
			},
			async saveDelivered() {
				this.loadingsave = true;
				try {
					const id = this.currentItem._id;
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'deliverSuppliesByMail',
							data: { id, Tracking:this.deliverSuppliesTracking }
						}
					});
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);
				}
				this.loadingsave = false;
			}
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			}
		}
    }
</script>